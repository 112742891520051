export default {

  months () {
    return [
      {
        id: 1,
        description: 'Janeiro',
      },
      {
        id: 2,
        description: 'Fevereiro',
      },
      {
        id: 3,
        description: 'Março',
      },
      {
        id: 4,
        description: 'Abril',
      },
      {
        id: 5,
        description: 'Maio',
      },
      {
        id: 6,
        description: 'Junho',
      },
      {
        id: 7,
        description: 'Julho',
      },
      {
        id: 8,
        description: 'Agosto',
      },
      {
        id: 9,
        description: 'Setembro',
      },
      {
        id: 10,
        description: 'Outubro',
      },
      {
        id: 11,
        description: 'Novembro',
      },
      {
        id: 12,
        description: 'Dezembro',
      },
    ]
  },

  years (past = 3) {
    const currentYear = (new Date()).getFullYear()

    const years = []

    for (let i = (currentYear - past); i <= currentYear; i++) {
      years.push({
        id: i,
        description: i,
      })
    }

    return years
  },

}
