import axios from '@/plugins/axios'

export default {

  async list (data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`cities?${params}`)
  },

  async get (id) {
    return await axios.get(`cities/${id}`)
  },

  async update (id, data) {
    return await axios.put(`cities/${id}`, data)
  },

  async listTypesOfApportionments (id, data) {
    const params = new URLSearchParams(data).toString()
    return await axios.get(`cities/${id}/types-of-apportionments?${params}`)
  },

  async insertTypeOfApportionment (cityId, data) {
    return await axios.post(`cities/${cityId}/types-of-apportionments`, data)
  },

  async updateTypeOfApportionment (cityId, cityTypeOfApportionmentId, data) {
    return await axios.put(`cities/${cityId}/types-of-apportionments/${cityTypeOfApportionmentId}`, data)
  },

  async deleteTypeOfApportionment (cityId, cityTypeOfApportionmentId) {
    return await axios.delete(`cities/${cityId}/types-of-apportionments/${cityTypeOfApportionmentId}`)
  },

  async importTypeOfApportionment (data) {
    return await axios.patch(`cities/types-of-apportionments/import`, data)
  },

  async listContacts (cityId) {
    return await axios.get(`cities/${cityId}/contacts`)
  },

  async insertContact (cityId, data) {
    return await axios.post(`cities/${cityId}/contacts`, data)
  },

  async updateContact (cityId, contactId, data) {
    return await axios.put(`cities/${cityId}/contacts/${contactId}`, data)
  },

  async deleteContact (cityId, contactId) {
    return await axios.delete(`cities/${cityId}/contacts/${contactId}`)
  },

  async changePasswordContact (cityId, contactId, data) {
    return await axios.patch(`cities/${cityId}/contacts/${contactId}/change-password`, data)
  },

}
